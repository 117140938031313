body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #252525;
  color: #f5f5f5;
}

p {
  margin-bottom: 0;
}
.row {
  margin: 0;
}
.warning {
  color:red;
}
.hide {
  display: none;
}
input, select, textarea {
  background-color: #000!important;
  border: #f5f5f5!important;
  color:#f5f5f5!important;
}
input::placeholder, select::placeholder, textarea::placeholder {
  color:#dfdfdf!important;
}
.system-error {
  text-align: center;
}
.system-error .btn {
  margin-top: 20px;
}
.btn:hover {
    background-color: #fff;
    color:#000;
}
.btn.disabled {
    opacity: 0.3;
}
.modal-content {
  background-color: #252525;
  border: 2px solid #fff;
}



.auth .title {
  text-align: center;
}
.auth .block {
  margin: 10% 0;
  box-shadow: 0px 16px 32px rgba(1, 1, 1, 0.5);
  border-radius: 10px;
  padding:35px;
}
.auth.forget .block h3 {
  font-size: 22px;
}
.auth .block form {
  margin: 20px 0;
}
.auth .block form label {
  color: #4D4D4D;
  margin-bottom: 6px;
  font-family: Arial, Helvetica, sans-serif;
  font-size:14px;
}
.auth .block form input {
  margin-bottom: 10px;
}
.auth .block form .btn {
  width: 100%;
}
.auth .privacy-accept input {
  margin-right:4px;
}
.auth .warning {
  text-align: center;
}





.header {
  border-bottom: 1px solid #737373;
  height: 60px;
}
.header p.position {
  font-size: 12px;
}
.header .left-panel {
  border-right: 1px solid #737373;
  float: left;
  width: 230px;
  height: 60px;
}
.header .left-panel .short-info {
  margin: 15px 25px;
}
.header .left-panel img {
  max-height: 30px;
  margin: 15px 25px;
}

.header .header-data {
  margin-left: 230px;
}
.header .header-data h3 {
  padding-top:15px;
  font-weight: 400;
  margin-left: 15px;
}
.header .header-data .logout {
  text-align: right;
  padding-top: 15px;
}

.menu {
  border-right: 1px solid #737373;
  position: absolute;
  width: 230px;
  height: 100%;
}
.menu .title p {
  margin-left: 25px;
  font-size: 12px;
  padding:8px 0;
  color: #737373;
  text-transform: uppercase;
}
.menu ul {
  list-style: none;
  padding-left: 0;
}
.menu ul li a {
  text-decoration: none;
  color:#f5f5f5;
  padding:6px 15px;
  display: block;
  border-bottom: 1px solid #737373;
}
.menu ul li a.active {
  background-color: #000;
}
.menu ul li a.active {
  border-bottom: 1px solid #000;
}
.menu ul li:hover {
  background-color: #402D2D;
}
.menu ul li a i {
  margin-right: 4px;
  font-size: 14px;
}


.main {
  margin-left: 230px;
  padding:15px;
}


.main form.profile {
  margin: 12px 0;
}
.main form.profile label {
  font-weight: 600;
  margin-bottom: 4px;
}
.main form.profile input {
  margin-bottom: 12px;
}
.main form.profile .btn {
  margin-top: 12px;
  float: left;
  margin-right: 12px;
}
.main form.profile .result {
  margin-top: 30px;
}





.page.users .filter-global {
    
}
.page.users .filter-global p {
    text-transform: uppercase;
    font-size: 14px;
    color: #737373;
}
.page.users .filter-global ul {
    list-style: none;
    padding-left: 0;
    margin-top: 12px;
}
.page.users .filter-global ul li {
    padding:8px 12px;
    border-bottom: 1px solid #402D2D;
    cursor: pointer;
    border-radius: 12px;
    margin-bottom: 2px;
}
.page.users .filter-global ul li span {
    float: right;
}
.page.users .filter-global ul li.active {
    background-color: #000;
}
.page.users .filter-global ul li:hover {
    background-color: #402D2D;
}
.page.users .filter-global label {
  margin-bottom: 4px;
}
.page.users .filter-global select {
  margin-bottom: 12px;
}

.page.users .filter .results-pagination {
    margin-top:25px;
}
.page.users .filter .results-pagination .find {
    font-size:20px;
}
.page.users .filter .results-pagination ul {
    list-style: none;
    padding-left: 0;
    float:right;
}
.page.users .filter .results-pagination ul li {
    float: left;
    margin-right:2px;
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid #402D2D;
    cursor: pointer;
}
.page.users .filter .results-pagination ul li.active {
    background-color: #000;
}
.page.users .filter .results-pagination ul li:hover {
    background-color: #402D2D;
}
.page.users .filter .results-pagination ul li.disabled {
    cursor: not-allowed;
    background-color: transparent;
}
.page.users .filter .results-pagination .find-count {
    float: left;
}
.page.users .filter .btn-info {
  margin-left: 12px;
}





.user-search-preview {
    border-radius: 8px;
    border: 1px solid #402D2D;
    padding:12px;
    cursor: pointer;
    margin-bottom: 8px;
}
.user-search-preview:hover {
    background-color: #402D2D;
}
.user-search-preview img {
    max-height: 100px;
    border-radius: 50%;
    float: left;
    margin-right: 12px;
}
.user-search-preview p span {
    color: #737373;
}
.user-search-preview ul {
    list-style: none;
    padding-left: 0;
    font-size: 14px;
}
.user-search-preview ul.short-info {
    margin-top: 12px;
    margin-bottom: 8px;
}
.user-search-preview ul.line {
    margin-bottom: 0;
}
.user-search-preview ul.line li {
    display: inline-block;
    min-width: 30%;
}
.user-search-preview ul.line li i {
    margin-right: 8px;
}



.page.user {
    margin-top:25px;
    margin-bottom:150px;
}
.page.user .poster {
    border-radius: 2px;
}
.page.user .preview h4 {
    margin:25px 0;
    text-align: center;
}
.page.user .preview p {
    margin-top:12px;
    font-size: 14px;
}
.page.user .preview a {
    text-decoration: none;
    color:#fff;
}
.page.user .preview p.title {
    margin-top:12px;
    font-size: 12px;
    color: #737373;
    text-transform: uppercase;
}
.page.user .preview i {
    margin-right:4px;
}
.page.user .preview .btn {
    margin: 15px 0;
}
.page.user .nav-link {
    font-size:14px;
    color:#f5f5f5;
    padding:6px 12px;
}
.page.user .nav-link.active {
    background-color: #000;
}
.page.user .tab-pane {
   margin-top: 12px;
}
.page.user label {
  margin-left: 4px;
  margin-bottom: 4px;
}
.page.user input, .page.user select {
  margin-bottom: 8px;
}



.page.user .schedule p {
    font-size: 14px;
    margin-bottom: 12px;
}
.page.user .schedule p span {
    font-size: 12px;
}
.page.user .schedule .btn {
    display: block;
    margin-bottom: 8px;
}



.statement-search-preview {
    border-radius: 8px;
    border: 1px solid #402D2D;
    padding:12px;
    cursor: pointer;
    margin-bottom: 8px;
}
.statement-search-preview a {
  text-decoration: none;
  color: #fff;
}
.statement-search-preview:hover {
    background-color: #402D2D;
}
.statement-search-preview img.poster {
    max-height: 100px;
    border-radius: 50%;
    float: left;
    margin-right: 12px;
}
.statement-search-preview img.user-expert-statement-grade-is-appreciated {
    height: 25px;
    margin-right: 4px;
    margin-top: 4px;
}
.statement-search-preview p span {
    color: #737373;
}
.statement-search-preview ul {
    list-style: none;
    padding-left: 0;
    font-size: 14px;
}
.statement-search-preview ul.short-info {
    margin-top: 12px;
    margin-bottom: 8px;
}
.statement-search-preview ul.line {
    margin-bottom: 0;
}
.statement-search-preview ul.line li {
    display: inline-block;
    min-width: 30%;
}
.statement-search-preview ul.line li i {
    margin-right: 8px;
}


.statement-admin ul {
    margin-top:15px;
}
.statement-admin .nav-tabs .nav-link {
    color:#fff;
}
.statement-admin .nav-tabs .nav-link.active {
    color:#000;
}
.statement-admin .new-statement-grade {
  margin: 15px 0 0 0;
}
.statement-admin .statement-control {
  margin-top: 15px;
}
.statement-page {
  display: none;
}
.statement-admin .tab-pane {
  margin-top: 15px;
}
.statement-admin .user-poster {
  max-height: 200px;
  border-radius: 4px;
}
.statement-page label {
  font-family: Arial,Helvetica,sans-serif;
  font-weight: 700;
  margin-bottom: 6px;
}
.statement-page input, .statement-page select {
  margin-bottom: 10px;
}
.statement-page ul.files-list {
  padding-left: 20px;
}
.statement-page ul.files-list a.delete {
  color: red;
  text-decoration: none;
}
.statement-page-header {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 25px;
}
.statement-page.active {
    display: block;
}
.statement-control {
    text-align: center;
}
.statement-control .btn {
    margin: 8px 12px;
}
.statement-control p {
    margin-top:12px;
    font-weight: 700;
}

.statement-admin .modal-new-user-expert-statement-grade .filter {
    margin-bottom: 12px;
}